/* src/BucketList.css */

.experience-list {
  padding: 20px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  background-size: cover;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.experience-item {
  position: relative;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  text-align: left;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.experience-item .badge {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
  font-size: 0.75rem;
}

.progress-badge {
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
  font-size: 0.75rem;
  margin-right: 5px;
}

.badge-todo {
  background-color: #888;
}

.badge-completed {
  background-color: #4CAF50;
}

.badge-in-progress {
  background-color: #FFC107;
}

.badge-planned {
  background-color: #2196F3;
}

.badge-postponed {
  background-color: #3b0aed;
}

.badge-undecided {
  background-color: #c5c5c5;
  margin-right: 0;
}

.experience-item.completed {
  background-color: rgba(224, 255, 224, 0.9);
}

.experience-item.inprogress {
  background-color: rgba(255, 240, 154, 0.9);
}

.experience-item.planned {
  background-color: rgba(184, 235, 255, 0.9);
}

.experience-item.incomplete {
  background-color: rgba(240, 240, 240, 0.9);
  color: #888;
}

.experience-item h3 {
  margin: 0;
  font-size: 1.25rem;
  padding-right: 25%;
}

.experience-item p {
  margin: 0px 0 25px;

}

.experience-item a {
  color: #007bff;
  text-decoration: none;
}

.experience-item h4 {
  margin: 0;
  font-size: 1rem;
}

.experience-item a:hover {
  text-decoration: underline;
}

.experience-item .watch-on {
  position: absolute;
  bottom: 5px;
  right: 10px;
  text-align: right;
}

.experience-item .watch-on h4 {
  font-size: 0.65rem;
}

.experience-item .watch-on .icon-links img {
  width: 24px;
  height: 24px;
  margin-left: 10px;
}

.experience-item .watch-on .icon-links a:first-child img {
  margin-left: 0;
}

/* Responsive design */
@media (max-width: 600px) {
  .experience-list {
    padding: 10px;
  }

  .experience-item h3 {
    font-size: 1.15rem;
  }

  .experience-item p {
    font-size: 0.85rem;
  }


  .experience-item {
    padding: 10px;
  }

  .experience-item .watch-on {
    bottom: 5px;
    right: 5px;
  }
}
