.contact-info {
    width: 100%;
    max-width: 600px;
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid #ddd;
    border-radius: 8px;
    margin: 15px 0;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    gap: 5px;
}

.contact-row {
    display: contents;
}
