body {
  margin: 0;
  font-family: 'proxima-nova', sans-serif;
  font-weight: 100;
  background-color: #f5f5f5;
  background-size: auto 100%;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.App-header {
  background-size: cover;
  background-position: top;
  text-align: center;
  color: white;
  background-color: black;
  border-bottom: 5px solid #ffcb05;
}

.App-header h1,
.App-header p {
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
  position: relative;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h3 {
  margin: 0;
}

a {
  text-decoration: none;
  color: blue;
}


footer {
  background-color: #282c34;
  color: white;
  padding: 10px;
  text-align: center;
  border-top: 5px solid #ffcb05;
}

h1,
h2,
h3,
p {
  margin: 0;
  padding: 0;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

.bm-burger-bars {
  background: #373a47;
}

.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-item-list a {
  color: #fff;
  padding: 0.8em;
  text-decoration: none;
}

.bm-item-list a:hover {
  color: #f39c12; /* Highlight on hover */
}

@media (max-width: 600px) {
  h3 {
    margin: 0;
    font-size: 1rem;
  }

  a {
    font-size: 1rem;
  }

  .App-header h1 {
    font-size: 1.25rem;
  }

  .App-header p {
    font-size: 0.75rem;
  }

  .App-header {
    padding: 10px;
  }

  main {
    max-width: 600px;
    margin: 0 auto;
    padding: 0 10px;
  }
}


@media (min-width: 600px) {
  .App-header h1 {
    font-size: 1.8rem;
  }

  .App-header {
    padding: 15px;
  }

  main {
    max-width: 600px;
    margin: 0 auto;
    padding: 0 10px;
  }
}


@media (min-width: 768px) {
  .App-header h1 {
    font-size: 2rem;
  }

  .App-header {
    padding: 20px;
  }

  main {
    max-width: 600px;
    margin: 0 auto;
    padding: 0 10px;
  }

  footer {
    padding: 20px;
  }
}