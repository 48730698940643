/* Portfolio.css */
.video-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* 3 columns */
}

.video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-player {
  width: 100%;
  /* Make the video fill the container */
  border-radius: 5px;
}

h3 {
  margin-top: 10px;
  font-size: 18px;
  color: #333;
}